import React from "react";
import { graphql } from "gatsby";

import { SEO } from "components";
import {
  EventAbout,
  EventHero,
  EventRequirements,
  EventSchedule,
  EventSponsors,
  EventStandings,
} from "views";

function EventPage({ data }) {
  const { event, settings } = data;
  const { settingsData } = settings;
  const { eventData } = event;

  return (
    <>
      <SEO
        title={eventData.title && eventData.title.text}
        description={eventData.excerpt && eventData.excerpt}
        imageOpenGraph={
          eventData.image && eventData.image.url
            ? eventData.image.url
            : settingsData.seo_image && settingsData.seo_image.url
        }
        imageTwitter={
          eventData.image && eventData.image.url
            ? eventData.image.url
            : settingsData.seo_image && settingsData.seo_image.url
        }
        imageAlt={
          eventData.image && eventData.image.url
            ? eventData.image.alt
            : settingsData.seo_image && settingsData.seo_image.alt
        }
      />
      <EventHero {...eventData} />
      <EventAbout {...eventData} />
      <EventSponsors {...eventData} />
      <EventSchedule {...eventData} />
      <EventRequirements {...eventData} />
      <EventStandings {...eventData} />
    </>
  );
}

export const query = graphql`
  query EventQuery($prismicId: ID!) {
    event: prismicEvent(prismicId: { eq: $prismicId }) {
      url
      uid
      eventData: data {
        title {
          text
        }
        image {
          url
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }

        start
        end
        excerpt
        sponsors {
          logo {
            url
          }
          name
        }
        about_title {
          text
        }
        about_body {
          html
          text
        }
        about_information {
          label
          value
        }
        about_buttons {
          label
          link {
            url
          }
        }
        requirements_title {
          text
        }
        schedule_title {
          text
        }
        schedule_items {
          time
          label
        }
        requirements_body {
          html
        }
        standings_title {
          text
        }
        standings_links {
          label
          link {
            url
          }
        }
        standings_buttons {
          link {
            url
          }
          label
        }
      }
    }
    settings: prismicSettings {
      settingsData: data {
        seo_image {
          url
          alt
        }
      }
    }
  }
`;

export default EventPage;
